import { Show, Stack } from '@chakra-ui/react'
import LoaderDesktop from './Loader_animation'
import animationData from './loading-anim.json'
import animationDataMobile from './loading-anim-mobile.json'
import { useBodyLock } from '@twlvxtwlv/hooks'

const Loader = () => {
  useBodyLock()

  return (
    <Stack position="absolute" top="0" right="0" w="100vw" h="100vh" zIndex="9999">
      <Show above="md">
        <LoaderDesktop animationData={animationData} />
      </Show>
      <Show below="md">
        <LoaderDesktop animationData={animationDataMobile} />
      </Show>
    </Stack>
  )
}
export default Loader
