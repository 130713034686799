import { useLottie } from 'lottie-react'
import { Box, Stack } from '@chakra-ui/react'

type LoaderAnimationProps = {
  animationData: object
}
const LoaderAnimation = ({ animationData }: LoaderAnimationProps) => {
  const options = {
    animationData: animationData,
    loop: true,
  }

  const { View } = useLottie(options)

  return (
    <Stack
      display="flex"
      w="100%"
      height="100vh"
      justifyContent="center"
      alignItems="center"
      background="txtBlack"
    >
      <Box>{View}</Box>
    </Stack>
  )
}
export default LoaderAnimation
